export default [{
  path: '/',
  name: 'dashBoard',
  component: () => import('@/views/siparis/siparis.vue'),
  meta: {
    pageTitle: 'DashBoard',
    breadcrumb: [{
      first: 'Restoran',
      dash: true,
    }],
  },
}, {
  path: '/error-404',
  name: 'error-404',
  component: () => import('@/views/pages/Error404.vue'),
  meta: {
    layout: 'full',
    resource: 'Auth',
  },
}, {
  path: '/login',
  name: 'login',
  component: () => import('@/views/pages/Login.vue'),
  meta: {
    layout: 'full',
    resource: 'Auth',
  },
}, {
  path: '/expariedLogin',
  name: 'expariedLogin',
  component: () => import('@/views/pages/expariedToken.vue'),
  meta: {
    layout: 'full',
    resource: 'Auth',
  },
}, {
  path: '/mutfak',
  name: 'mutfak',
  component: () => import('@/views/mutfak/mutfak.vue'),
}, {
  path: '/order/:masaID/:adisyonID',
  name: 'order',
  component: () => import('@/views/siparis/order.vue'),
}, {
  path: '/salon&masa',
  name: 'salon&masa',
  component: () => import('@/views/tanimlamalar/area.vue'),
}, {
  path: '/menu',
  name: 'menu',
  component: () => import('@/views/tanimlamalar/menu.vue'),
}, {
  path: '/kapaliVardiya',
  name: 'kapaliVardiya',
  component: () => import('@/views/pages/vardiyaClosed.vue'),
  meta: {
    layout: 'full',
    resource: 'Auth',
  },
}]
