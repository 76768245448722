<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view/>
    </component>
    <scroll-to-top v-if="enableScrollToTop"/>
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import jwt_decode from 'jwt-decode'
import { useWindowSize, useCssVar, useNetwork } from '@vueuse/core'
import store from '@/store'
import router from '@/router'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BSpinner } from 'bootstrap-vue'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    ScrollToTop,
    BSpinner,
  },
  data() {
    return {
      isOnline: true,
      count: 0,
    }
  },
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    const colors = [
      'primary',
      'secondary',
      'success',
      'info',
      'warning',
      'danger',
      'light',
      'dark',
    ]
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
          `--${colors[i]}`,
          document.documentElement,
      )
          .value
          .trim()
    }
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
          useCssVar(
              `--breakpoint-${breakpoints[i]}`,
              document.documentElement,
          )
              .value
              .slice(0, -2),
      )
    }
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const {
      skin,
      skinClasses,
    } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout
    if (skin.value === 'dark') document.body.classList.add('dark-layout')
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })
    return {
      skinClasses,
      enableScrollToTop,
    }
  },
  created() {
    const { routerTransition } = useAppConfig()
    routerTransition.value = localStorage.getItem('restoranPageEffect') || 'none'
    this.departmanData()
  },
  mounted() {
    // todo Axios Default Manager
    axios.defaults.baseURL = store.state.POST_URL
    axios.defaults.headers.post['Content-Type'] = 'application/json'
    axios.interceptors.response.use(
        response => {
          const {
            userToken,
            result,
          } = response.data
          if (userToken) {
            store.commit('setToken', userToken)
            store.commit('setUserData', jwt_decode(userToken))
          }
          if (response.data.result) {
            if (response.data.result.status === 204) {
              store.state.isVardiya = false
              // todo Api, vardiya kapalı ise login methodunda 204 atarsa buradaki yönlendirme silinecek
              router.push({ name: 'kapaliVardiya' })
            }
          }
          if (result && result.errMsg) {
            const title = result.status === 200 ? 'Başarılı' : 'Hata!'
            const icon = result.status === 200 ? 'ThumbsUpIcon' : 'ThumbsDownIcon'
            const variant = result.status === 200 ? 'success' : 'danger'
            this.$toast({
              component: ToastificationContent,
              props: {
                title,
                text: result.errMsg,
                icon,
                variant,
              },
            })
          }
          return response.data
        },
        error => {
          if (error.response) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: error.response.data.errMsg || error.response.data.result.errMsg,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                },
            )
          }
          if (error.response && error.response.data.tokenStatus === 503) {
            store.state.isAuth = false
            router.push({
              name: 'expariedLogin',
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oturum süresi dolmuştur.',
                text: 'Lütfen şifrenizi giriniz.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }
          return error.response.data
        },
    )
    // todo Network Manager
    const { isOnline } = useNetwork()
    this.isOnline = isOnline.value
    this.$watch(
        () => isOnline.value,
        function (newValue) {
          if (newValue === false) {
            const h = this.$createElement
            this.count += 1
            const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, [
              h('b-spinner', {
                props: {
                  type: 'grow',
                  small: true,
                },
              }),
              h('strong', ' İnternet Bağlantısını Kontrol Edin '),
              h('b-spinner', {
                props: {
                  type: 'grow',
                  small: true,
                },
              }),
            ])
            const vNodesTitle = h('div', { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] }, [
              h('strong', { class: 'mr-2' }, 'Hata'),
              h('small', { class: 'ml-auto font-italics' }, 'Bağlantı Kesildi'),
            ])
            this.$bvToast.toast([vNodesMsg], {
              title: [vNodesTitle],
              solid: true,
              variant: 'danger',
              autoHideDelay: 200000,
              toastClass: 'ethernet-toast',
              toaster: 'b-toaster-bottom-center',
            })
          } else {
            document.getElementsByClassName('ethernet-toast')[0].style.display = 'none'
            this.isOnline = isOnline.value
          }
        },
    )
  },
  methods: {
    departmanData() {
      if (store.state.userToken || localStorage.getItem('restoranUserToken')) {
        const fd = new FormData()
        fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
        fd.append('serviceName', 'restoran')
        fd.append('methodName', 'departmanListesi')
        axios.post(store.state.POST_URL, fd)
            .then(response => {
              if (response.data.result.status === 200) {
                store.commit({
                  type: 'SET_DEPARTMAN_LIST',
                  action: 1,
                  list: response.data.departmanList,
                })
              } else if (response.data.result.status === 204) {
                store.state.isVardiya = false
                store.state.isAuth = true
                // router.push({ name: 'kapaliVardiya' })
              }
            })
            .catch(error => {
              // if (error.response.status === 503) {
              //   router.push({ name: 'expariedLogin' })
              // }
            })
      } else {
        // router.push({ name: 'login' })
      }
    },
  },
}
</script>

<style>
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.list-card .card-header {
  display: none !important;
}

.card-header-row {
  width: 102% !important;
  position: absolute;
  background-color: lightslategrey;
  margin: 0 -1.5% !important;
  border-radius: 6px;
}

.list-card {
  width: 100% !important;
}

.list-card .card-title {
  transform: translateY(50%) !important;
}

.list-card .card-btn {
  transform: translateY(8%) !important;
  float: right;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.search-bar button {
  border-color: #b7b4c3 !important;
}

.pull-up {
  transition: 400ms;
}

.bg-none {
  background-image: none !important;
}

.content-wrapper {
  transform: translateY(-30px) !important;
}

.d-grid {
  display: grid;
}

table thead th {
  color: white;
  padding: 0.62rem 1rem !important;
}

@media screen and (max-width: 599px) {
  .row-btn {
    display: none !important;
  }
}

@media screen and (min-width: 600px) {
  .mobile-row-btn {
    display: none !important;
  }
}

</style>
