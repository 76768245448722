import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import router from './router'
import store from './store'
import App from './App.vue'

// Icons
import './icons'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.directive('numericOnly', {
  bind(el) {
    el.addEventListener('keypress', event => {
      if ((event.keyCode < 48 || event.keyCode > 57) && event.keyCode !== 44 && event.keyCode !== 46) {
        event.preventDefault()
      }
    })
    el.addEventListener('input', () => {
      let regex = /^\d*[,.]?\d*$/
      if (!regex.test(el.value)) {
        el.value = el.value.slice(0, -1)
      }
    })
  },
})

Vue.directive('maxElevenCharacter', {
  bind(el) {
    el.addEventListener('keypress', event => {
      // just number character
      if (event.keyCode < 48 || event.keyCode > 57) {
        event.preventDefault()
      }
      // inputun uzunluğu 11'i aşarsa, son karakteri sil
      if (el.value.length >= 11) {
        el.value = el.value.slice(0, -1)
      }
    })
  },
})

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
