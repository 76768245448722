export default [
  {
    path: '/cariListe',
    name: 'cariListe',
    component: () => import('@/views/cari/cariList.vue'),
  },
  {
    path: '/cariDetay/:id',
    name: 'cariDetay',
    component: () => import('@/views/cari/cariDetay.vue'),
  },
  {
    path: '/cariEkstre/:id',
    name: 'cariEkstre',
    component: () => import('@/views/cari/cariEkstre.vue'),
  },
]
