import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'font-awesome-animation/css/font-awesome-animation.min.css'

import {
  faBolt,
  faChevronDown,
  faGlassWaterDroplet,
  faGear,
  faBell,
  faBagShopping,
  faTableCells,
  faPen,
  faMoneyBills,
  faPlus,
  faMinus,
  faUpDownLeftRight,
  faCheckDouble,
  faTrash,
  faAngleLeft,
  faArrowLeft,
  faXmark,
  faUsers,
  faUser,
  faComment,
  faUtensils,
  faTurkishLiraSign,
  faLocationDot,
  faEnvelope,
  faHashtag,
  faPhone,
  faBuilding,
  faPaperclip,
  faCircleInfo,
  faCircle,
  faFloppyDisk,
  faAddressCard,
  faFileContract,
  faEye,
  faTrashCan,
  faChevronLeft,
  faBoxArchive,
  faClockRotateLeft,
  faFileImport,
  faCreditCard,
  faReceipt,
  faCoins,
  faEllipsisVertical,
  faSquareCaretRight,
  faPrint,
  faDiagramNext,
  faDiagramSuccessor,
  faAnglesRight,
  faCheck,
  faMoneyBillTransfer,
  faCashRegister,
  faStar,
  faArrowTrendUp,
  faArrowTrendDown,
  faCircleXmark,
  faMoneyBillTrendUp,
  faObjectUngroup,
  faCircleUser,
  faCircleNotch,
  faCircleDot,
  faTableCellsLarge,
  faList,
  faClone,
  faBars,
  faDatabase,
  faLock,
  faTableList,
  faUtensilSpoon,
  faClock,
  faUsersLine,
  faChartLine,
  faCircleStop,
  faArrowPointer,
} from '@fortawesome/free-solid-svg-icons'
import { faFloppyDisk as regularFloppyDisk } from '@fortawesome/free-regular-svg-icons'

library.add(faGlassWaterDroplet, faGear, faBell, faBagShopping, faTableCells, faPen, faPlus, faMinus, faUpDownLeftRight, faCheckDouble, faTrash, faAngleLeft, faArrowLeft, faXmark, faUsers, faComment, faUtensils, faTurkishLiraSign, faUser, faLocationDot, faEnvelope, faHashtag, faPhone, faBuilding, faPaperclip, faCircleInfo, faCircle, faFloppyDisk, faAddressCard, faFileContract, faEye, faTrashCan, faChevronLeft, faBoxArchive, faClockRotateLeft, faFileImport, faCreditCard, faReceipt, faCoins, faEllipsisVertical, faSquareCaretRight, faPrint, faDiagramNext, faDiagramSuccessor, faAnglesRight, faCheck, faChevronDown, faBolt, faMoneyBills, regularFloppyDisk, faMoneyBillTransfer, faCashRegister, faStar, faArrowTrendUp, faArrowTrendDown, faCircleXmark, faMoneyBillTrendUp, faObjectUngroup, faCircleUser, faCircleNotch, faCircleDot, faTableCellsLarge, faList, faClone, faBars, faDatabase, faLock, faTableList, faUtensilSpoon, faClock, faUsersLine, faChartLine, faCircleStop, faArrowPointer)
Vue.component('font-awesome-icon', FontAwesomeIcon)
