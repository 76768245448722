import Vue, { ref } from 'vue'
import Vuex from 'vuex'
import jwt_decode from 'jwt-decode'
import router from '@/router'
import axios from 'axios'
import store from '@/store'

// Modules
import restoran from './restoran'
import cari from './cari'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import raporlar from './raporlar'
import ayarlar from './ayarlar'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    POST_URL: 'https://api.oys.web.tr/webservis.php',
    isAuth: false,
    isVardiya: false,
    role: 0,
    level: 0,
    userData: [],
    vardiyaData: [],
    userToken: '',
    colors: {
      appHeaderColor: '#317873',
      headerRowColor: '#4A55A2',
      tableHeaderColor: '#7091F5',
      footerColor: '#a78ac0',
      dangerColor: '#F31559',
      warningColor: '#FFB84C',
      successColor: '#10A19D',
      primaryColor: '#1B6B93',
    },
  },
  getters: {
    GET_VARDIYA_DATA: state => state.vardiyaData,
    TOKEN(state) {
      return state.userToken || localStorage.getItem('restoranUserToken')
    },
    isAuthenticated(state) {
      return state.isAuth
    },
  },
  mutations: {
    SET_COLORS(state, colors) {
      const color = colors.colors
      state.colors.appHeaderColor = color.appHeaderColor
      state.colors.dangerColor = color.dangerColor
      state.colors.footerColor = color.footerColor
      state.colors.headerRowColor = color.headerRowColor
      state.colors.primaryColor = color.primaryColor
      state.colors.successColor = color.successColor
      state.colors.tableHeaderColor = color.tableHeaderColor
      state.colors.warningColor = color.warningColor
    },
    SET_VARDIYA_DATA: (state, payload) => (payload.action === 1 ? state.vardiyaData = payload.list : state.vardiyaData = []),
    removeUserData(state) {
      state.role = 0
      state.level = 0
      state.userData = []
    },
    setUserData(state, jwt) {
      state.role = jwt.gui
      state.level = jwt.level
      state.userData = jwt
      localStorage.setItem('restoranFirmaKodu', jwt.firmaKodu)
      localStorage.setItem('restoranFullName', jwt.FullName)
      localStorage.setItem('restoranUserName', jwt.userName)
      localStorage.setItem('restoranRole', jwt.gui)
      localStorage.setItem('restoranLevel', jwt.level)
    },
    setToken(state, token) {
      if (token) {
        state.userToken = token
        localStorage.setItem('restoranUserToken', token)
      }
    },
    removeToken(state) {
      state.userToken = ''
      localStorage.removeItem('restoranUserToken')
    },
  },
  actions: {
    async authControl({
      commit,
      state,
    }) {
      let token = localStorage.getItem('restoranUserToken')
      if (token) {
        const fd = new FormData()
        fd.append('serviceName', 'dashBoard')
        fd.append('crm_token', token)
        return new Promise((resolve, reject) => {
          axios.post(store.state.POST_URL, fd, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
            .then(res => {
              if (res.data.result.status === 200 && jwt_decode(res.data.userToken).defaultModul === 'restoran') {
                commit('setUserData', jwt_decode(res.data.userToken))
                state.isVardiya = true
                state.isAuth = true
                commit('setToken', res.data.userToken)
                resolve(res)
                commit({
                  type: 'SET_COLORS',
                  colors: res.data.color,
                })
                commit({
                  type: 'SET_DASH_DATA',
                  action: 1,
                  list: res.data.dashData,
                })
                commit({
                  type: 'SET_VARDIYA_DATA',
                  action: 1,
                  list: res.data.vardiyaData,
                })
              } else if (res.data.result.status === 204) {
                commit('setToken', res.data.userToken)
                commit('setUserData', jwt_decode(res.data.userToken))
                state.isAuth = true
                reject(204)
              } else {
                reject(res)
                state.isAuth = false
              }
            })
            .catch(err => {
              reject(err)
              state.isAuth = false
            })
        })
      }
      // router.push('/login')
      // return true
    },
    logIn({
      commit,
      state,
    }, authData) {
      const fd = new FormData()
      fd.append('serviceName', 'account')
      fd.append('methodName', 'logIn')
      fd.append('user', authData.user)
      fd.append('pwd', authData.pwd)
      fd.append('firmaKodu', authData.firmaKodu)
      fd.append('modul', 'restoran')
      return new Promise((resolve, reject) => {
        axios.post(store.state.POST_URL, fd, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(res => {
            if (res.status === 200) {
              if (jwt_decode(res.userToken).defaultModul === 'restoran') {
                commit('setUserData', jwt_decode(res.userToken))
                commit('setToken', res.userToken)
                state.isVardiya = true
                state.isAuth = true
                resolve(res)
              } else {
                state.isAuth = false
                reject('moduleError')
              }
            } else if (res.status === 204) {
              // todo Api, vardiya kapalı ise login methodunda 204 atarsa kapaliVardiya sayfasına yönlendirme buradan yapılır
              state.isVardiya = false
              state.isAuth = true
              commit('setUserData', jwt_decode(res.userToken))
              commit('setToken', res.userToken)
              router.push('/kapaliVardiya')
              // resolve(res)
            } else {
              state.isAuth = false
              reject(res)
            }
          })
          .catch(err => {
            state.isAuth = false
            reject(err)
          })
      })
    },
    tablePrint({}, authData) {
      const originalStyles = []
      for (const node of [...document.querySelectorAll('link[rel=\'stylesheet\'], style')]) {
        originalStyles.push(node.outerHTML)
      }
      const customStyle = `<style>
      .b-table td,.b-table th{
         text-align: left;
      }
      .b-table th div{
         color:black;
      }
      .b-table p {
         color:black;
         margin: 0;
      }
      .b-table td{
         border: 1px solid grey;
      }
      
      .table td,.table th{
         text-align: left;
      }
      .table th div{
         color:black;
      }
      .table p {
         color:black;
         margin: 0;
      }
      .table td{
         border: 1px solid grey;
      }

    </style>`
      const combinedStyles = originalStyles.join('\n') + customStyle
      var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')
      WinPrint.document.write(`<!DOCTYPE html>
      <html>
      <head>
         ${combinedStyles}
      </head>
      <body>
         ${authData}
      </body>
      </html>`)
      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
    },
  },
  modules: {
    ayarlar,
    restoran,
    raporlar,
    cari,
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
})
