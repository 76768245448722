import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import restoran from './routes/restoran'
import cari from './routes/cari'
import raporlar from './routes/raporlar'
import ayarlar from './routes/ayarlar'
import jwt_decode from 'jwt-decode'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: process.env.NODE_ENV === 'production' ? 'hash' : 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [{
    path: '/',
    redirect: { name: 'dashBoard' },
  }, ...restoran, ...cari, ...raporlar, ...ayarlar, {
    path: '*',
    redirect: 'error-404',
  }],
})

router.beforeEach(async (to, from, next) => {
  const isLoggedIn = store.getters.isAuthenticated
  const { isVardiya } = store.state
  const { role } = store.state
  const isLoginPage = to.path === '/login' || to.path === '/expariedLogin'
  const isKapaliVardiyaPage = to.path === '/kapaliVardiya'
  if (isLoginPage) {
    if (isLoggedIn) {
      return next(false)
    }
    return next()
  }
  if (isKapaliVardiyaPage) {
    if (isLoggedIn && !isVardiya) {
      return next()
    }
    if (from.name === null) {
      return next({ name: 'dashBoard' })
    }
    return next(false)
  }
  if (!isLoggedIn) {
    try {
      const response = await store.dispatch('authControl')
      const { gui } = jwt_decode(response.data.userToken)
      if (gui === 'mutfak') {
        if (to.path === '/mutfak') {
          return next()
        }
        return next({ name: 'mutfak' })
      }
      return next()
    } catch (error) {
      if (error === 204) {
        return next({ name: 'kapaliVardiya' })
      }
      return next({ name: 'login' })
    }
  }
  if (isVardiya) {
    if (role === 'mutfak') {
      if (to.path === '/mutfak') {
        return next()
      }
      return next(false)
    }
    return next()
  }
  return next(false)
})

// ? For splash screen
router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
