export default {
  state: {
    cariList: [],
    cariDetay: [],
    cariEkstre: [],
  },
  getters: {
    GET_CARI_LIST: state => state.cariList,
    GET_CARI_DETAY: state => state.cariDetay,
    GET_CARI_EKSTRE: state => state.cariEkstre,
    GET_EKSTRE_GIRIS_TURU: () => girisTuru => {
      switch (girisTuru) {
        case '1':
          return 'Nakit'
        case '2':
          return 'Kredi Kartı'
        case '3':
          return 'Havale'
        default:
          return 'Nakit'
      }
    },
  },
  mutations: {
    SET_CARI_LIST: (state, payload) => (payload.action === 1 ? state.cariList = payload.list : state.cariList = []),
    SET_CARI_DETAY: (state, payload) => (payload.action === 1 ? state.cariDetay = payload.list : state.cariDetay = []),
    SET_CARI_EKSTRE: (state, payload) => (payload.action === 1 ? state.cariEkstre = payload.list : state.cariEkstre = []),
  },
  actions: {},
}
