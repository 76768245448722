export default {
  state: {
    menuList: [],
    menuGrupList: [],
    adisyonDetay: [],
    dashData: [],
    siparisList: [],
    hazirSiparisler: [],
  },
  getters: {
    GET_HAZIR_SIPARISLER: state => state.hazirSiparisler,
    GET_SIPARIS_LIST: state => state.siparisList,
    GET_ADISYON_DETAY: state => state.adisyonDetay,
    GET_MENU_LIST: state => state.menuList,
    GET_MENU_GRUP_LIST: state => state.menuGrupList,
    GET_DASH_DATA: state => state.dashData,
  },
  mutations: {
    SET_HAZIR_SIPARISLER: (state, payload) => (payload.action === 1 ? state.hazirSiparisler = payload.list : state.hazirSiparisler = []),
    SET_SIPARIS_LIST: (state, payload) => (payload.action === 1 ? state.siparisList = payload.list : state.siparisList = []),
    SET_ADISYON_DETAY: (state, payload) => (payload.action === 1 ? state.adisyonDetay = payload.list : state.adisyonDetay = []),
    SET_MENU_LIST: (state, payload) => (payload.action === 1 ? state.menuList = payload.list : state.menuList = []),
    SET_MENU_GRUP_LIST: (state, payload) => (payload.action === 1 ? state.menuGrupList = payload.list : state.menuGrupList = []),
    SET_DASH_DATA: (state, payload) => (payload.action === 1 ? state.dashData = payload.list : state.dashData = []),
  },
  actions: {},
}
