export default {
  state: {
    vardiyaDetay: [],
    vardiyaList: [],
    detayliTablo: [],
    tahsilatList: [],
    adisyonList: [],
    kasaRaporuFinans: [],
    kasaRaporuTables: [],
    giderList: [],
  },
  getters: {
    GET_KASA_RAPORU_FINANS: state => state.kasaRaporuFinans,
    GET_KASA_RAPORU_TABLES: state => state.kasaRaporuTables,
    GET_DETAYLI_TABLO: state => state.detayliTablo,
    GET_GIDER_LIST: state => state.giderList,
    GET_ADISYON_LIST: state => state.adisyonList,
    GET_TAHSILAT_LIST: state => state.tahsilatList,
    GET_VARDIYA_DETAY: state => state.vardiyaDetay,
    GET_VARDIYA_LIST: state => state.vardiyaList,
  },
  mutations: {
    SET_KASA_RAPORU_FINANS: (state, payload) => (payload.action === 1 ? state.kasaRaporuFinans = payload.list : state.kasaRaporuFinans = []),
    SET_KASA_RAPORU_TABLES: (state, payload) => (payload.action === 1 ? state.kasaRaporuTables = payload.list : state.kasaRaporuTables = []),
    SET_DETAYLI_TABLO: (state, payload) => (payload.action === 1 ? state.detayliTablo = payload.list : state.detayliTablo = []),
    SET_GIDER_LIST: (state, payload) => (payload.action === 1 ? state.giderList = payload.list : state.giderList = []),
    SET_ADISYON_LIST: (state, payload) => (payload.action === 1 ? state.adisyonList = payload.list : state.adisyonList = []),
    SET_TAHSILAT_LIST: (state, payload) => (payload.action === 1 ? state.tahsilatList = payload.list : state.tahsilatList = []),
    SET_VARDIYA_LIST: (state, payload) => (payload.action === 1 ? state.vardiyaList = payload.list : state.vardiyaList = []),
    SET_VARDIYA_DETAY: (state, payload) => (payload.action === 1 ? state.vardiyaDetay = payload.list : state.vardiyaDetay = []),
  },
  actions: {},
}
