export default [{
  path: '/vardiyaDetayi/:id',
  name: 'vardiyaDetayi',
  component: () => import('@/views/raporlar/vardiyaDetayi.vue'),
}, {
  path: '/detayliTablo/:id',
  name: 'detayliTablo',
  component: () => import('@/views/raporlar/detayliTablo.vue'),
}, {
  path: '/giderListesi/:id',
  name: 'giderListesi',
  component: () => import('@/views/raporlar/giderListesi.vue'),
}, {
  path: '/adisyonListesi/:id',
  name: 'adisyonListesi',
  component: () => import('@/views/raporlar/adisyonListesi.vue'),
}, {
  path: '/vardiyaListesi',
  name: 'vardiyaListesi',
  component: () => import('@/views/raporlar/vardiyaListesi.vue'),
}, {
  path: '/kasaRaporu',
  name: 'kasaRaporu',
  component: () => import('@/views/raporlar/kasaRaporu.vue'),
}]
