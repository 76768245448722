export default {
  state: {
    departmanList: [],
    kullaniciList: [],
  },
  getters: {
    GET_KULLANICI_LIST: state => state.kullaniciList,
    GET_DEPARTMAN_LIST: state => state.departmanList,
  },
  mutations: {
    SET_KULLANICI_LIST: (state, payload) => (payload.action === 1 ? state.kullaniciList = payload.list : state.kullaniciList = []),
    SET_DEPARTMAN_LIST: (state, payload) => (payload.action === 1 ? state.departmanList = payload.list : state.departmanList = []),
  },
  actions: {},
}
